<template>
  <div>
    <el-form label-position="right">
      <div type="flex" justify="center">
          <div class="form-group" style="display: flex; justify-content: center;">
            <!-- {{field}} -->
            <el-form-item
              label="Authorized Signature"
              style="width: 200px;"
            >
              <el-select
                v-model="field.authorized_signature"
                :default-first-option="true"
                :filterable="true"
                :no-data-text="'Authorized Signature'"
              >
                <el-option
                  v-for="(signature,index) of authorizedSignatures"
                  :key="index"
                  :label="signature.name"
                  :value="signature._id"
                  :aria-selected="(index==0)?true:false"
                >
                  <span style="float: left">{{ signature.name }}</span>
                  <span
                    style="float: right; color: #8492a6; font-size: 13px"
                  >{{ signature.designation }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name:"templates-formComponents-AuthorizedSignature",
  components: {},
  computed: {
    ...mapGetters("company", ["getAuthorizedSignatures"]),
    authorizedSignatures() {
      return this.getAuthorizedSignatures || [];
    },
    selectedValueData() {
      return (this.getAuthorizedSignatures || []).find(
        signature => signature._id == this.field.authorized_signature
      );
    }
  },
  props: ["field"],
  data() {
    return {};
  },
  mounted() {
    this.fetchAuthorizedSignature();
  },
  methods: {
    async fetchAuthorizedSignature() {
      await this.$store.dispatch("company/getCompanyAuthorizationSignatures", {
        get_all: true
      });
    },
    setSelectedValue(data) {
      console.log({ data });
    }
  }
};
</script>

<style lang="scss">
</style>